import { useTranslations } from "next-intl";
import { useRouter } from "next/navigation";
import React, { useEffect, useState } from "react";
import { cn } from "../../helpers/classNames";
import { getLinkPartsFromSlug } from "../../helpers/getLinkPartsFromSlug";
import { getRoute } from "../../helpers/urls/getRoute";
import { useIsPageChange } from "../../hooks/useIsPageChange";
import { useLocateMe } from "../../hooks/useLocateMe";
import {
    useCountry,
    useIsPersistingForbidden,
    usePageParams,
    usePageType,
} from "../ApplicationState/hooks";
import { sendError } from "../ErrorBoundary/helpers";
import { ItemLoading } from "./itemLoading";
import styles from "./suggestList.module.scss";

type Props = {
    onItemClick: () => void;
    suggestionClicked: boolean;
};
export const ItemLocateMe: React.FC<Props> = ({
    onItemClick,
    suggestionClicked,
}) => {
    const country = useCountry();
    const pageType = usePageType();
    const pageParams = usePageParams();
    const router = useRouter();
    const isPersistingForbidden = useIsPersistingForbidden();
    const t = useTranslations("component.autoSuggest");
    const errorMessage = t("locateMe.errorMessage");
    const [label, setLabel] = useState(t("locateMe.defaultLabel"));
    const {
        locateMe,
        permissionDenied,
        currentLocation,
        isLoading: isLocatingMe,
    } = useLocateMe();
    const [isLoading, setIsLoading] = useState(isLocatingMe);
    const isPageChange = useIsPageChange();

    useEffect(() => {
        if (isPageChange) {
            setIsLoading(false);
            onItemClick();
        }
    }, [isPageChange, onItemClick]);

    try {
        const geoLocate = async (forceRelocate: boolean): Promise<void> => {
            setIsLoading(true);
            return locateMe(forceRelocate).then((posData) => {
                if (posData?.location?.seoString) {
                    const { code, seoString } = getLinkPartsFromSlug(
                        posData.location.seoString
                    );
                    if (
                        pageType?.isForecastTodayPage &&
                        pageParams.code === code
                    ) {
                        setIsLoading(false);
                        onItemClick();
                    } else {
                        router.push(
                            getRoute({
                                parameters: {
                                    pageName: "forecastToday",
                                    seoString,
                                    code,
                                },
                                country,
                            })
                        );
                    }
                } else {
                    setLabel(t("locateMe.tryAgain"));
                }
            });
        };

        return (
            <ul className={styles.group} key="locate-me-group">
                <li className={styles.title} data-testid="myLocation">
                    <span className="icon-crosshairs" />
                    {t("locateMe.myLocation")}
                </li>
                <li className={styles.item} key="locate-me-item">
                    {isPersistingForbidden || permissionDenied ? (
                        <div
                            className={cn(
                                styles.left,
                                styles.clickIndicator,
                                styles.itemInner
                            )}
                            role="button"
                            tabIndex={0}
                            onClick={(e) => e.preventDefault()}
                        >
                            {errorMessage}
                        </div>
                    ) : isLoading ? (
                        <ItemLoading />
                    ) : currentLocation ? (
                        <div className={styles.itemInner}>
                            <div
                                className={cn(
                                    styles.left,
                                    suggestionClicked && styles.inactive
                                )}
                                role="button"
                                tabIndex={0}
                                onClick={() =>
                                    !suggestionClicked && void geoLocate(false)
                                }
                            >
                                {currentLocation.location?.cityName}
                            </div>
                            <div
                                className={cn(
                                    styles.right,
                                    suggestionClicked && styles.inactive
                                )}
                                role="button"
                                tabIndex={0}
                                onClick={() =>
                                    !suggestionClicked && void geoLocate(true)
                                }
                                data-testid="relocate"
                            >
                                {t("locateMe.relocate")}
                                <span className="icon-reload" />
                            </div>
                        </div>
                    ) : (
                        <div
                            className={cn(
                                styles.left,
                                styles.itemInner,
                                suggestionClicked && styles.inactive
                            )}
                            role="button"
                            tabIndex={0}
                            onClick={() =>
                                !suggestionClicked && void geoLocate(false)
                            }
                            data-testid="defaultLabel"
                        >
                            {label}
                        </div>
                    )}
                </li>
            </ul>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
