import { useTranslations } from "next-intl";
import React from "react";
import { cn } from "../../helpers/classNames";
import styles from "./suggestList.module.scss";

export const ItemLoading: React.FC = () => {
    const t = useTranslations("component.autoSuggest");

    return (
        <div className={cn(styles.left, styles.itemInner)}>
            <span>{t("locateMe.pleaseWait")}</span>
            <span className={styles.dot}>.</span>
            <span className={styles.dot}>.</span>
            <span className={styles.dot}>.</span>
        </div>
    );
};
