import { useEffect, useState } from "react";
import { defaultCities } from "../../app/common/config";
import { getWindow } from "../../helpers/getWindow";
import {
    useCountry,
    useIsPersistingForbidden,
} from "../ApplicationState/hooks";
import { sendError } from "../ErrorBoundary/helpers";
import config from "./config";
import type { FavoriteType, LocalStorageFavoriteItem } from "./types";

export const useFavorites = (): Array<LocalStorageFavoriteItem> => {
    const window = getWindow();
    const country = useCountry();
    const isPersistingForbidden = useIsPersistingForbidden();
    const [favorites, setFavorites] = useState<Array<LocalStorageFavoriteItem>>(
        []
    );
    const defaultCity = defaultCities[country];
    const localStorageKey = config.localStorageKey[country];

    useEffect(() => {
        if (isPersistingForbidden) {
            setFavorites([defaultCities[country]]);

            return;
        } else {
            try {
                const favoriteStore =
                    window?.localStorage.getItem(localStorageKey);
                const favoriteStoreObj = JSON.parse(
                    favoriteStore ?? '{"favorites": []}'
                ) as { favorites: Array<FavoriteType> };

                if (favoriteStoreObj.favorites.length > 0) {
                    const favorites = favoriteStoreObj.favorites
                        .filter(
                            (item: FavoriteType) =>
                                Object.keys(item.forceOverwrite ?? [])
                                    .length === 0
                        )
                        .map((item: FavoriteType) => ({
                            code: item.cityCode,
                            name: item.cityName,
                            slug: item.seoString,
                        }));

                    setFavorites(favorites);
                } else {
                    setFavorites([defaultCity]);
                }
            } catch (e) {
                sendError(e);
            }
        }
    }, [window, country, defaultCity, localStorageKey, isPersistingForbidden]);
    return favorites;
};
