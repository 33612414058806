import type {
    CLSMetricWithAttribution,
    FCPMetricWithAttribution,
    INPMetricWithAttribution,
    LCPMetricWithAttribution,
    TTFBMetricWithAttribution,
} from "web-vitals/attribution";
import { pushDataLayer } from "./dataLayer";
import { getWindow } from "./getWindow";

type MyPerformanceEntry = {
    // Use own type, as PeformanceEntry(layout-shift) is no standard yet.
    // So define missing items itself.
    // See: https://wicg.github.io/layout-instability/#layoutshift https://developer.mozilla.org/en-US/docs/Web/API/LayoutShift
    value: number;
    hadRecentInput: boolean;
    loadTime?: number;
    interactionId?: number;
} & PerformanceEntry;

type MyPerformanceObserverInit = {
    durationThreshold?: number;
} & PerformanceObserverInit;

export const coreWebVitals = (): void => {
    reportMetrics();
    if (process.env.NEXT_PUBLIC_DEPLOY_ENV !== "production") {
        logMetrics();
    }
};

const logMetrics = (): void => {
    const window = getWindow();
    if (window?.PerformanceObserver) {
        let totalCLS = 0;
        const obverver = new window.PerformanceObserver((entryList) => {
            for (const entry of entryList.getEntries() as Array<MyPerformanceEntry>) {
                const { entryType, hadRecentInput, interactionId } = entry;

                if (!hadRecentInput && entryType === "layout-shift") {
                    totalCLS += entry.value;
                    // eslint-disable-next-line no-console
                    console.warn(
                        "CWV/CLS",
                        `total CLS: ${totalCLS.toString()}`,
                        entry
                    );
                } else if (entryType === "largest-contentful-paint") {
                    // eslint-disable-next-line no-console
                    console.warn(
                        "CWV/LCP",
                        `ms: ${(entry.loadTime ?? -1).toString()}`,
                        entry
                    );
                } else if (interactionId && entryType === "event") {
                    // eslint-disable-next-line no-console
                    console.warn(
                        "CWV/INP",
                        `ms: ${entry.duration.toString()}, event: ${entry.name}`,
                        entry
                    );
                }
            }
        });
        obverver.observe({
            type: "layout-shift",
            buffered: true,
        } as MyPerformanceObserverInit);
        obverver.observe({
            type: "largest-contentful-paint",
            buffered: true,
        } as MyPerformanceObserverInit);
        obverver.observe({
            type: "event",
            durationThreshold: 16,
            buffered: true,
        } as MyPerformanceObserverInit);
    }
};

const reportMetrics = (): void => {
    const window = getWindow();

    const metricFn = (
        metric:
            | CLSMetricWithAttribution
            | FCPMetricWithAttribution
            | INPMetricWithAttribution
            | LCPMetricWithAttribution
            | TTFBMetricWithAttribution
    ): void => {
        pushDataLayer({
            type: "webVitals",
            event: "measurement",
            eventCategory: "Web Vitals",
            eventAction: metric.name,
            eventLabel: metric.id,
            eventValue: Math.round(metric.value),
            attribution: metric.attribution,
        });
    };

    if (window) {
        void (async (): Promise<void> => {
            // make sure to only import helpers once
            const { onCLS, onFCP, onINP, onLCP, onTTFB } = await import(
                "web-vitals/attribution"
            );
            onCLS(metricFn);
            onFCP(metricFn);
            onINP(metricFn, { reportAllChanges: true });
            onLCP(metricFn);
            onTTFB(metricFn);
        })();
    }
};
