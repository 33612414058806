import type { SearchResult } from "../../network/matlocq/types/search";

export const iconData: Record<
    keyof SearchResult,
    {
        title: string;
        name: string;
    }
> = {
    locations: {
        title: "groups.locations",
        name: "icon-pin",
    },
    touristicRegions: {
        title: "groups.touristicRegions",
        name: "icon-palm",
    },
    videos: {
        title: "groups.videos",
        name: "icon-play",
    },
    skiAreas: {
        title: "groups.skiAreas",
        name: "snow-icon-skiarea",
    },
    livecams: {
        title: "groups.livecams",
        name: "icon-camera",
    },
};
