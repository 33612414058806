import type { CountryType, Nullable } from "../../../@types/generic";
import { fetcher } from "../index";
import type { LocationBestForCoordinates } from "../types/locationsBestForCoordinates";

export const getBestLocation = (
    latitude: number,
    longitude: number,
    country: CountryType
): Promise<Nullable<LocationBestForCoordinates>> =>
    fetcher<LocationBestForCoordinates>(
        {
            path: `/locations/best-for-coordinates/${latitude.toString()}/${longitude.toString()}/`,
        },
        {
            country,
        }
    ).catch(() => null);
