type GetLinkPartsFromSlug = {
    code: string;
    seoString: string;
};

export const getLinkPartsFromSlug = (slug: string): GetLinkPartsFromSlug => {
    slug = slug.endsWith("/") ? slug.slice(0, -1) : slug;
    const parts = slug.replace(".html", "").split("/");
    return {
        code: parts.pop() ?? "",
        seoString: parts.join("/"),
    };
};
