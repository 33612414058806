export const OPTIMIZELY_SDK_KEY =
    process.env.NEXT_PUBLIC_DEPLOY_ENV === "production"
        ? "Lq8QtXoyMEwkaziHKJ8Ec"
        : "xry4mSCgJbRvtQMgJy8sU";
export const defaultCities = {
    de: {
        name: "Berlin",
        code: "DE0001020",
        seoString: "deutschland/berlin",
        slug: "deutschland/berlin/DE0001020.html",
    },
    at: {
        name: "Wien",
        code: "ATAT10678",
        seoString: "oesterreich/wien",
        slug: "oesterreich/wien/ATAT10678.html",
    },
    ch: {
        name: "Bern",
        code: "CH0CH0324",
        seoString: "schweiz/bern",
        slug: "schweiz/bern/CH0CH0324.html",
    },
    pl: {
        name: "Warszawa",
        code: "PL0MZ0005",
        seoString: "polska/warszawa",
        slug: "polska/warszawa/PL0MZ0005/",
    },
};
