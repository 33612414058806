"use client";

import NextScript from "next/script";
import React from "react";
import { useIsWebview } from "../ApplicationState/hooks";
import { useConsent, useIsPay } from "../Consent/hooks";
import { sendError } from "../ErrorBoundary/helpers";

const gtmConfig = {
    standalone: {
        containerId: "GTM-KJKGJLT",
        auth: "3BJ86-10kpqbMJM0hxWtoA",
        preview: "env-763",
    },
    webview: {
        containerId: "GTM-N8DH226V",
        auth: "UPjI1qTRassyCCm93CHvrg",
        preview: "env-400",
    },
};

export const GTM: React.FC = () => {
    const consent = useConsent();
    const isPay = useIsPay();
    const isWebview = useIsWebview();

    try {
        if (!consent || isPay) {
            return null;
        }
        const config = isWebview ? gtmConfig.webview : gtmConfig.standalone;

        return (
            <NextScript
                id="gtm-head"
                strategy="lazyOnload"
                dangerouslySetInnerHTML={{
                    __html:
                        process.env.NEXT_PUBLIC_DEPLOY_ENV === "production"
                            ? `
                           (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','${config.containerId}');`
                            : `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${config.auth}&gtm_preview=${config.preview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','${config.containerId}');`,
                }}
            />
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
