import NextLink from "next/link";
import React, {
    useMemo,
    useState,
    type Dispatch,
    type SetStateAction,
} from "react";
import { cn } from "../../helpers/classNames";
import type { SearchResultItem } from "../../network/matlocq/types/search";
import { useCountry, useIsWebview } from "../ApplicationState/hooks";
import { sendError } from "../ErrorBoundary/helpers";
import { getSuggestionLink } from "./helpers";
import { ItemLoading } from "./itemLoading";
import styles from "./suggestList.module.scss";

type Props = {
    item: SearchResultItem;
    searchValue: string;
    suggestionClicked: boolean;
    setSuggestionClicked: Dispatch<SetStateAction<boolean>>;
};

export const SuggestListItem: React.FC<Props> = ({
    item,
    searchValue,
    suggestionClicked,
    setSuggestionClicked,
}) => {
    const isWebview = useIsWebview();
    const country = useCountry();
    const [isLoading, setIsLoading] = useState(false);

    const title = useMemo(() => {
        // Escape "(" and ")" to have a working RegExp. Otherwise, RegExp will crash if user enters a brace.
        const parts = item.title.split(
            new RegExp(
                `(${searchValue.replace("(", "\\(").replace(")", "\\)")})`,
                "gi"
            )
        );

        return (
            <span className="title">
                {parts.map((part, index) =>
                    part.toLowerCase() === searchValue.toLowerCase() ? (
                        <b key={index}>{part}</b>
                    ) : (
                        part
                    )
                )}
            </span>
        );
    }, [item.title, searchValue]);

    try {
        const href = getSuggestionLink(item, isWebview, country);
        const isNextLink = [
            // "locations",
            // "livecams",
            "skiAreas",
        ].includes(item.type);

        const onClick = (): void => {
            if (!suggestionClicked) {
                setSuggestionClicked(true);
                setIsLoading(true);
            }
        };

        return (
            <li
                className={styles.item}
                onClick={onClick}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="button"
                tabIndex={0}
            >
                {isLoading ? (
                    <ItemLoading />
                ) : suggestionClicked ? (
                    <span className={cn(styles.inactive, styles.itemInner)}>
                        {title}
                    </span>
                ) : isNextLink ? (
                    <NextLink
                        href={href}
                        role="button"
                        className={styles.itemInner}
                    >
                        {title}
                    </NextLink>
                ) : (
                    <a href={href} role="button" className={styles.itemInner}>
                        {title}
                    </a>
                )}
            </li>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
