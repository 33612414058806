import { useEffect } from "react";
import type { Nullable } from "../../@types/generic";
import { getWindow } from "../../helpers/getWindow";
import { usePageType } from "../ApplicationState/hooks";

type UseHeaderScroll = {
    ref: React.RefObject<Nullable<HTMLDivElement>>;
    styles: Record<string, string>;
};

export const useHeaderScroll = ({ ref, styles }: UseHeaderScroll): void => {
    const pageType = usePageType();
    const window = getWindow();

    useEffect(() => {
        let lastScrollY = window?.scrollY ?? 0;
        const handleScroll = (): void => {
            if (ref.current && !pageType?.isLivecamDetailPage) {
                const currentScrollY = window?.scrollY ?? 0;
                // reset translate on initial position and when scrolling up, otherwise hide

                if (
                    currentScrollY === 0 ||
                    (currentScrollY > 0 && currentScrollY < lastScrollY)
                ) {
                    ref.current.classList.remove(styles.isHidden ?? "");
                    ref.current.classList.remove(styles.hasShadow ?? "");
                } else if (currentScrollY > ref.current.offsetHeight) {
                    ref.current.classList.add(styles.isHidden ?? "");
                }

                // add box shadow when scrolling down
                if (
                    currentScrollY > 0 &&
                    !ref.current.classList.contains(styles.isHidden ?? "")
                ) {
                    ref.current.classList.add(styles.hasShadow ?? "");
                } else {
                    ref.current.classList.remove(styles.hasShadow ?? "");
                }
                lastScrollY = currentScrollY;
            }
        };

        if (!pageType?.isLivecamDetailPage) {
            window?.addEventListener("scroll", handleScroll, { passive: true });
        }

        return (): void => {
            window?.removeEventListener("scroll", handleScroll);
        };
    }, [pageType?.isLivecamDetailPage, window, ref, styles]);
};
