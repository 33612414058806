import { useEffect, useRef, useState } from "react";
import type { Nullable } from "../@types/generic";
import type { UserPositionType } from "../@types/locateMe";
import { sendEventToNativeApp } from "../bridge/helpers";
import type { UserLocation } from "../bridge/types";
import {
    useCountry,
    useIsPersistingForbidden,
    useIsWebview,
    useUserLocation,
} from "../components/ApplicationState/hooks";
import { getWindow } from "../helpers/getWindow";
import { getBestLocation } from "../network/matlocq/api/locationsBestForCoordinates";

const localStorageKey = {
    de: "wetterCom_userlocation",
    at: "wetterCom_userlocation",
    ch: "wetterCom_userlocation",
    pl: "wetterCom_userlocation-pl",
};

export const useLocateMe = (): {
    locateMe: (forceRelocate: boolean) => Promise<Nullable<UserPositionType>>;
    permissionDenied: Nullable<boolean>;
    currentLocation: Nullable<UserPositionType>;
    isLoading: boolean;
    isError: boolean;
} => {
    const window = getWindow();
    const country = useCountry();
    const isPersistingForbidden = useIsPersistingForbidden();
    const [currentLocation, setCurrentLocation] =
        useState<Nullable<UserPositionType>>(null);
    const [permissionDenied, setPermissionDenied] =
        useState<Nullable<boolean>>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const isWebview = useIsWebview();
    const locateMeWebview = useLocateMeWebview();

    useEffect(() => {
        if (!isWebview && window?.navigator.permissions) {
            void window.navigator.permissions
                .query({ name: "geolocation" })
                .then((obj) => {
                    setPermissionDenied(obj.state === "denied");
                });
        }
    }, [isWebview, window]);

    useEffect(() => {
        if (!isWebview && !isPersistingForbidden) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const position: UserPositionType =
                JSON.parse(
                    window?.localStorage.getItem(localStorageKey[country]) ??
                        "{}"
                ) ?? {};
            if (Object.entries(position).length !== 0) {
                setCurrentLocation(position);
            }
        }
    }, [isWebview, isPersistingForbidden, window?.localStorage, country]);

    const locateMe = async (
        forceRelocate: boolean
    ): Promise<Nullable<UserPositionType>> => {
        if (isWebview && locateMeWebview !== undefined) {
            setIsLoading(true);

            return locateMeWebview().then(
                async (pos: UserLocation | undefined) => {
                    if (pos?.lat && pos.lng) {
                        const location = await getBestLocation(
                            pos.lat,
                            pos.lng,
                            country
                        );

                        if (!location) {
                            return null;
                        }

                        const locationToReturn = {
                            location: {
                                cityName: location.name,
                                cityCode: location.code,
                                seoString: location.slug,
                                latitude: location.coordinates.latitude,
                                longitude: location.coordinates.longitude,
                            },
                        };
                        setCurrentLocation(locationToReturn);

                        setIsLoading(false);
                        return locationToReturn;
                    } else {
                        setPermissionDenied(true);
                        setIsLoading(false);
                        return null;
                    }
                }
            );
        } else {
            if (!isPersistingForbidden && window && !forceRelocate) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const position: UserPositionType =
                    JSON.parse(
                        window.localStorage.getItem(localStorageKey[country]) ??
                            "{}"
                    ) ?? {};
                if (
                    Object.entries(position).length !== 0 &&
                    position.location?.latitude &&
                    position.location.longitude
                ) {
                    return Promise.resolve(position);
                }
            }

            return new Promise((resolve, reject) => {
                setIsLoading(true);
                window?.navigator.geolocation.getCurrentPosition(
                    resolve,
                    reject,
                    {
                        enableHighAccuracy: false,
                        timeout: 10000,
                        maximumAge: 0,
                    }
                );
            })
                .then(async (_pos) => {
                    const pos = _pos as GeolocationPosition;
                    const location = await getBestLocation(
                        pos.coords.latitude,
                        pos.coords.longitude,
                        country
                    );

                    if (!location) {
                        return null;
                    }

                    const locationToReturn = {
                        location: {
                            cityName: location.name,
                            cityCode: location.code,
                            seoString: location.slug,
                            latitude: location.coordinates.latitude,
                            longitude: location.coordinates.longitude,
                        },
                    };

                    setCurrentLocation(locationToReturn);
                    if (!isPersistingForbidden) {
                        localStorage.setItem(
                            localStorageKey[country],
                            JSON.stringify(locationToReturn)
                        );
                    }

                    return locationToReturn;
                })
                .catch((err: unknown) => {
                    // error code = 1 ==> Permission denied
                    // @see https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
                    if ((err as GeolocationPositionError).code === 1) {
                        setPermissionDenied(true);
                    }

                    setIsLoading(false);
                    setIsError(true);
                    return Promise.reject(
                        new Error((err as GeolocationPositionError).message)
                    );
                });
        }
    };

    return {
        locateMe,
        permissionDenied,
        currentLocation,
        isLoading,
        isError,
    };
};

const useLocateMeWebview = ():
    | undefined
    | (() => Promise<UserLocation | undefined>) => {
    const userLocation = useUserLocation();
    const resolve =
        useRef<(value: UserLocation | undefined) => void>(undefined);
    const isWebview = useIsWebview();

    useEffect(() => {
        if (
            userLocation?.lat &&
            userLocation.lng &&
            resolve.current &&
            isWebview
        ) {
            resolve.current(userLocation);
        } else if (resolve.current) {
            resolve.current(undefined);
        }
    }, [userLocation, isWebview]);

    if (!isWebview) {
        return undefined;
    }

    return (): Promise<UserLocation | undefined> =>
        new Promise((_resolve) => {
            sendEventToNativeApp({ type: "geolocateUser" });
            resolve.current = _resolve;
        });
};
