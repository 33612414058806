import type { SWRConfiguration } from "swr";
import { sendError } from "../components/ErrorBoundary/helpers";

export const swrConfig: SWRConfiguration = {
    errorRetryCount: 0,
    // no auto re-fetching (polling) but on focus change, e.g. while navigating between forecast pages.
    refreshInterval: 0,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any, key: string, config: any) => {
        const customAttributes: Record<string, string> = {};
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (error?.response?.data?.error) {
            for (const [key, value] of Object.entries(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                error.response.data.error
            )) {
                customAttributes[`custom.useSWR.onError.${key}`] =
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    `${value}`;
            }
        } else {
            customAttributes[`custom.useSWR.onError.error`] =
                JSON.stringify(error);
            customAttributes[`custom.useSWR.onError.key`] = JSON.stringify(key);
            customAttributes[`custom.useSWR.onError.config`] =
                JSON.stringify(config);
        }
        sendError(error, customAttributes);
        return false;
    },
};
