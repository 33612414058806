import { useTranslations } from "next-intl";
import React, { useState } from "react";
import { cn } from "../../helpers/classNames";
import { isDachProduct } from "../../helpers/isDachProduct";
import { useLocateMe } from "../../hooks/useLocateMe";
import type { TranslationKey } from "../../i18n/types";
import type { SearchResult } from "../../network/matlocq/types/search";
import {
    useCountry,
    useIsPersistingForbidden,
} from "../ApplicationState/hooks";
import { sendError } from "../ErrorBoundary/helpers";
import { useFavorites } from "../Favorites/hooks";
import { iconData } from "./iconData";
import { ItemLocateMe } from "./itemLocateMe";
import styles from "./suggestList.module.scss";
import { SuggestListItem } from "./suggestListItem";

type Props = {
    searchResult: SearchResult | undefined;
    searchValue: string;
    onItemClick: () => void;
};

export const SuggestList: React.FC<Props> = ({
    searchResult,
    searchValue,
    onItemClick,
}) => {
    const country = useCountry();
    const isPersistingForbidden = useIsPersistingForbidden();
    const isEmpty =
        typeof searchResult === "undefined" ||
        Object.keys(searchResult).every(
            (type) => searchResult[type as keyof SearchResult].length === 0
        );
    const favorites = useFavorites();
    const { permissionDenied } = useLocateMe();
    const [suggestionClicked, setSuggestionClicked] = useState(false);
    const t = useTranslations("component.autoSuggest");
    try {
        return (
            <div
                className={cn(
                    styles.container,
                    !isDachProduct(country) && styles.containerProduct
                )}
            >
                {searchValue && isEmpty ? (
                    <ul className={styles.group} key="error-group">
                        <li className={styles.title}>
                            <span className="icon-important" />
                            {t("fallbacks.groupTitle")}
                        </li>
                        <li
                            className={cn(styles.item, styles.itemInner)}
                            key="error-item"
                            data-testid="differentInput"
                        >
                            {t("fallbacks.differentInput")}
                        </li>
                    </ul>
                ) : searchValue && !isEmpty ? (
                    Object.keys(searchResult).map((type) => {
                        const suggestions =
                            searchResult[type as keyof SearchResult];
                        const icon = iconData[type as keyof SearchResult];

                        return suggestions.length > 0 ? (
                            <ul className={styles.group} key={type}>
                                {suggestions.length > 0 && (
                                    <li className={styles.title}>
                                        <>
                                            <span className={icon.name} />
                                            {t(
                                                icon.title as TranslationKey<
                                                    typeof t
                                                >
                                            )}
                                        </>
                                    </li>
                                )}
                                {suggestions.map((item) => (
                                    <SuggestListItem
                                        key={item.title}
                                        item={item}
                                        searchValue={searchValue}
                                        suggestionClicked={suggestionClicked}
                                        setSuggestionClicked={
                                            setSuggestionClicked
                                        }
                                    />
                                ))}
                            </ul>
                        ) : null;
                    })
                ) : !isPersistingForbidden ? (
                    <>
                        {!permissionDenied && (
                            <ItemLocateMe
                                onItemClick={onItemClick}
                                suggestionClicked={suggestionClicked}
                            />
                        )}
                        <ul className={styles.group} key="favorites-group">
                            <li className={styles.title}>
                                <span className="icon-pin" />
                                {t("lastVisited")}
                            </li>
                            {favorites.slice(0, 3).map((favorite) => (
                                <SuggestListItem
                                    key={favorite.code}
                                    item={{
                                        type: "favorite",
                                        title: favorite.name,
                                        code: favorite.code,
                                        slug: favorite.slug,
                                    }}
                                    searchValue={searchValue}
                                    suggestionClicked={suggestionClicked}
                                    setSuggestionClicked={setSuggestionClicked}
                                />
                            ))}
                        </ul>
                        {permissionDenied && (
                            <ItemLocateMe
                                onItemClick={onItemClick}
                                suggestionClicked={suggestionClicked}
                            />
                        )}
                    </>
                ) : null}
            </div>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
