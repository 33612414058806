export type SearchResultLocationItem = {
    type: "locations";
    title: string;
    code: string;
    seoString: string;
    latitude: number;
    longitude: number;
};

type SearchResultVideoItem = {
    type: "videos";
    title: string;
    id: string;
    seoString: string;
};

type SearchResultTouristicRegionItem = {
    type: "touristicRegions";
    title: string;
    slug: string;
};

type SearchResultLivecamItem = {
    type: "livecams";
    title: string;
    id: string;
    seoString: string;
};

type SearchResultSkiAreaItem = {
    type: "skiAreas";
    title: string;
    id: string;
    normalized: {
        name: string;
        country: string;
    };
};

type SearchResultFavoriteItem = {
    type: "favorite";
    title: string;
    code: string;
    slug: string;
};

export type SearchResultItem =
    | SearchResultLocationItem
    | SearchResultVideoItem
    | SearchResultTouristicRegionItem
    | SearchResultLivecamItem
    | SearchResultSkiAreaItem
    | SearchResultFavoriteItem;

export type SearchResult = {
    locations: Array<SearchResultLocationItem>;
    videos: Array<SearchResultVideoItem>;
    touristicRegions: Array<SearchResultTouristicRegionItem>;
    livecams: Array<SearchResultLivecamItem>;
    skiAreas: Array<SearchResultSkiAreaItem>;
};

export const isSearchResultLocationItem = (
    obj: SearchResultItem
): obj is SearchResultLocationItem => obj.type === "locations";

export const isSearchResultVideoItem = (
    obj: SearchResultItem
): obj is SearchResultVideoItem => obj.type === "videos";

export const isSearchResultTouristicRegionItem = (
    obj: SearchResultItem
): obj is SearchResultTouristicRegionItem => obj.type === "touristicRegions";

export const isSearchResultLivecamItem = (
    obj: SearchResultItem
): obj is SearchResultLivecamItem => obj.type === "livecams";

export const isSearchResultSkiAreaItem = (
    obj: SearchResultItem
): obj is SearchResultSkiAreaItem => obj.type === "skiAreas";

export const isSearchResultFavoriteItem = (
    obj: SearchResultItem
): obj is SearchResultFavoriteItem => obj.type === "favorite";
