const url = {
    autosuggest: "https://sayt.wettercomassets.com/v1/prod/suggest",
    imageAsset: "https://cs3.wettercomassets.com/wcomv5/images",
    fileAsset: "https://cs3.wettercomassets.com/wcomv5/files",
    miaStaticAsset: "https://cs3.wettercomassets.com/wcomv6/maps",
    miaStaticRadar: "https://static-radar.wettercomassets.com/prod",
    weatherState: "https://cs3.wettercomassets.com/wcomv5/images/icons/weather",
    external: "https://cs3.wettercomassets.com/wcomv6/external",
    thumbnails: "https://cs3.wettercomassets.com/thumbnails/variants/",
    featureIconPath: "/react/assets/icons/feature",
    assetPath: "/react/assets",
    wcomV5: "https://cs3.wettercomassets.com/wcomv5",
    fileManager: "https://cs3.wettercomassets.com/filemanager/upload/",
};

export default url;
