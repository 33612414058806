import config from "./url";
import type { GetUrlOptions, UrlObjectType } from "./urlTypes";

/**
 * url helper to build urls based on the given data. For urls inside next app, use getRoute helper.
 * @param data see `UrlObjectType` for more information
 * @returns string with the formatted url
 */
export const getUrl = (
    data: UrlObjectType,
    options?: GetUrlOptions
): string => {
    let url = "";

    const getLinkFromAssetPath = (path: string): string => {
        // 1. for local use correct asset path
        if (!process.env.NEXT_PUBLIC_DEPLOY_ENV) {
            return path;
        }
        // 2. check if asset bucket has support for same host redirection
        const index = path.search(/(thumbnails|wcomv5|wcomv6)/);
        if (index === -1) {
            return path;
        }
        // 3. use relative path = same host
        return `/${path.substring(index)}`;
    };
    const replaceExtension = (src: string): string =>
        src
            .replace(/\.jpg/g, ".webp")
            .replace(/\.jpeg/g, ".webp")
            .replace(/\.gif/g, ".webp")
            .replace(/\.png/g, ".webp");

    switch (data.type) {
        case "weatherState":
            url = `${getLinkFromAssetPath(config.weatherState)}${data.path}`;
            break;
        case "imageAsset":
            url = `${getLinkFromAssetPath(config.imageAsset)}${data.path}`;
            break;
        case "miaStaticAsset":
            url = `${getLinkFromAssetPath(config.miaStaticAsset)}/${data.country}_${data.title}.webp`;
            break;
        case "miaStaticRadar":
            url = `${config.miaStaticRadar}${data.path}`;
            break;
        case "autosuggest": {
            let searchPath = "search";
            if (data.country === "at" || data.country === "ch") {
                searchPath = `${searchPath}-${data.country}`;
            }
            url = `${config.autosuggest}/${searchPath}/${encodeURI(
                data.searchTerm
            ).toLowerCase()}?token=${
                process.env.NEXT_PUBLIC_API_TOKEN ?? "unknown"
            }`;
            break;
        }
        case "thumbnails":
            url = `${getLinkFromAssetPath(config.thumbnails)}${data.path}`;
            break;
        case "path":
            url = data.path;
            break;
        case "external":
            url = `${config.external}${data.path}`;
            break;
        case "assetPath":
            url = `${config.assetPath}/${data.path}`;
            break;
        case "adFramework":
            switch (data.framework) {
                case "CP_AT":
                    url = `${config.wcomV5}/js/programmatic/WET_AT_ROS.js`;
                    break;
                case "CP_CH":
                    url = `${config.wcomV5}/js/programmatic/WET_CH_ROS.js`;
                    break;
                case "CP_DE":
                    url = `${config.wcomV5}/js/programmatic/WET_ROSv2.js`;
                    break;
                case "SOM":
                    url = `${config.wcomV5}/som/header.min.js`;
                    break;
                case "gam":
                    url = `${config.wcomV5}/js/internal/gam.min.js`;
                    break;
            }
            break;
        case "pollenImage":
            url = `${config.wcomV5}/images/pollen/dictionary/${data.name}_${data.size}.webp`;
            break;
        case "pollenText":
            url = `${config.wcomV5}/static/pollen/dictionary/${data.name}.md`;
            break;
        case "fileManager":
            url = `${config.fileManager}${data.path}`;
            break;
    }

    if (!options?.noWebpReplacement) {
        url = replaceExtension(url);
    }
    if (
        url.startsWith("/") ||
        url.startsWith("https") ||
        url.startsWith("mailto:")
    ) {
        return url;
    }

    return `/${url}`;
};
