import type { CountryType, Nullable } from "../../@types/generic";
import { Webview } from "../../bridge/types";
import { getRoute } from "../../helpers/urls/getRoute";
import {
    isSearchResultFavoriteItem,
    isSearchResultLivecamItem,
    isSearchResultLocationItem,
    isSearchResultSkiAreaItem,
    isSearchResultTouristicRegionItem,
    isSearchResultVideoItem,
    type SearchResultItem,
} from "../../network/matlocq/types/search";

export const getSuggestionLink = (
    suggestion: SearchResultItem,
    isWebview: Nullable<boolean>,
    country: CountryType
): string => {
    if (isSearchResultLocationItem(suggestion)) {
        return getRoute({
            parameters: {
                pageName: "forecastToday",
                seoString: suggestion.seoString,
                code: suggestion.code,
            },
            country,
        });
    }

    if (isSearchResultSkiAreaItem(suggestion)) {
        return getRoute({
            parameters: {
                pageName: "skiAreaDetail",
                id: suggestion.id,
                name: suggestion.normalized.name,
                countryName: suggestion.normalized.country,
            },
            searchParameters: isWebview ? { webview: Webview.app } : undefined,
            country,
        });
    }

    if (isSearchResultVideoItem(suggestion)) {
        return getRoute({
            parameters: {
                pageName: "video",
                title: suggestion.seoString,
                id: suggestion.id,
            },
            country,
        });
    }

    if (isSearchResultLivecamItem(suggestion)) {
        return getRoute({
            parameters: {
                pageName: "livecamDetailPage",
                seoString: suggestion.seoString,
                id: suggestion.id,
            },
            country,
        });
    }

    if (isSearchResultTouristicRegionItem(suggestion)) {
        return suggestion.slug;
    }

    if (isSearchResultFavoriteItem(suggestion)) {
        const parts = suggestion.slug.split("/");
        const seoString = parts.slice(0, -1).join("/");

        return getRoute({
            parameters: {
                pageName: "forecastToday",
                code: suggestion.code,
                seoString,
            },
            country,
        });
    }

    return "";
};
