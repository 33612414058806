"use client";

import { useTranslations } from "next-intl";
import NextLink from "next/link";
import { useSearchParams } from "next/navigation";
import React from "react";
import dayjs from "../../app/common/dayjs";
import { getRoute } from "../../helpers/urls/getRoute";
import { getUrl } from "../../helpers/urls/getUrl";
import { useCountry } from "../ApplicationState/hooks";
import { sendError } from "../ErrorBoundary/helpers";
import { Image } from "../Image";
import styles from "./styles.module.scss";

export const WebsiteLogo: React.FC = () => {
    const country = useCountry();
    const params = useSearchParams();
    const t = useTranslations("component.websiteLogo");
    const logoImage =
        country === "pl"
            ? getUrl({
                  type: "imageAsset",
                  path: `/wetter_com_logo_${country}.svg?20250221-1552`,
              })
            : getUrl({
                  type: "imageAsset",
                  path: "/wetter_com_logo.svg?20221222-1055",
              });

    try {
        const today = dayjs();
        const year = dayjs().year();
        const christmas = dayjs(`${year.toString()}-12-24`).startOf("day");
        const afterChristmas = dayjs(`${year.toString()}-12-27`).endOf("day");

        const promotions = {
            christmas: today.isBetween(christmas, afterChristmas, "day", "[]"),
            pride:
                (today.year() === year && today.month() === 5) ||
                params.get("pride") === "true",
        };

        return (
            <NextLink
                data-label="Header_wetter.com"
                aria-label={t("ariaLabel")}
                title={t("title")}
                href={getRoute({ parameters: { pageName: "home" }, country })}
                className={styles.container}
            >
                <Image
                    className={styles.wcomLogo}
                    src={logoImage}
                    width="138px"
                    height="30px"
                    imgTagSize={{ width: 138, height: 30 }}
                    alt={t("alt")}
                    title={t("title")}
                    lazy={false}
                    preload
                />
                {promotions.christmas && (
                    <div
                        className={styles.promotionWrapper}
                        data-testid="christmas-promotion"
                    >
                        <Image
                            src={getUrl({
                                type: "imageAsset",
                                path: "/christmas-hat-dark.webp?20231212-1430",
                            })}
                            width="31px"
                            height="23px"
                            imgTagSize={{ width: 31, height: 23 }}
                            alt={t("promotions.christmas")}
                            title={t("promotions.christmas")}
                            lazy={false}
                        />
                    </div>
                )}
                {promotions.pride && (
                    <Image
                        className={styles.pridePromotion}
                        src={getUrl({
                            type: "imageAsset",
                            path: "/logo_pride_addition.svg?20240528-1437",
                        })}
                        width="64px"
                        height="30px"
                        imgTagSize={{ width: 64, height: 30 }}
                        alt={t("promotions.pride")}
                        title={t("promotions.pride")}
                        lazy={false}
                    />
                )}
            </NextLink>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
