"use client";

import { useTranslations } from "next-intl";
import React, { type FocusEvent, useState } from "react";
import { cn } from "../../helpers/classNames";
import { getWindow } from "../../helpers/getWindow";
import { isDachProduct } from "../../helpers/isDachProduct";
import { getRoute } from "../../helpers/urls/getRoute";
import { useDebouncedValue } from "../../hooks/useDebouncedValue";
import { useLocation } from "../../network/matlocq/queries/Location";
import { useAutosuggestSearch } from "../../network/matlocq/queries/Search";
import {
    useCountry,
    usePageParams,
    usePageType,
} from "../ApplicationState/hooks";
import { sendError } from "../ErrorBoundary/helpers";
import styles from "./index.module.scss";
import { SuggestList } from "./suggestList";

export const AutoSuggest: React.FC = () => {
    const country = useCountry();
    const pageType = usePageType();
    const window = getWindow();
    const t = useTranslations("component.autoSuggest");
    const [value, setValue] = useState<string>("");
    const debouncedValue = useDebouncedValue<string>(value, 100);
    const { data: suggestions } = useAutosuggestSearch(debouncedValue);
    let placeholder = t("defaultPlaceholder");
    const [overlayShown, setOverlayShown] = useState(false);
    const pageParams = usePageParams();
    const { data: location } = useLocation(pageParams.code ?? null);
    const showOverlay = (show = true): void => {
        setOverlayShown(show);
        if (!show) {
            // when the menu suggestions are scrolled, reset and scroll to top
            window?.scrollTo(0, 0);
        }
    };

    if (location && pageType?.isAnyForecastPage) {
        const { name, zipcodes, country, admin1 } = location;
        const zip = zipcodes[0];
        if (zip && name && country.code) {
            placeholder = `${zip} - ${name} - ${country.code}`;
        } else if (name && admin1.location.name && country.code) {
            placeholder = `${name} - ${admin1.location.name} - ${country.code}`;
        }
    }

    try {
        if (pageType?.isPlainPage) {
            return null;
        }

        return (
            <>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div
                    className={cn(
                        styles.container,
                        !isDachProduct(country) && styles.containerProduct
                    )}
                    id="autosuggest-container"
                    onBlur={(e: FocusEvent): void => {
                        const role =
                            e.relatedTarget?.getAttribute("role") ?? "";
                        // check for roles of listitem itself and inner <a>
                        if (!["button", "listitem"].includes(role)) {
                            e.preventDefault();
                            showOverlay(false);
                        }
                    }}
                >
                    <form
                        className={cn(
                            styles.form,
                            overlayShown && styles.formOpen,
                            !isDachProduct(country) && styles.formProduct
                        )}
                        role="search"
                        action={getRoute({
                            parameters: {
                                pageName: "searchResults",
                                searchTerm: value,
                            },
                            country,
                        })}
                    >
                        <input
                            className={styles.input}
                            role="searchbox"
                            name="q"
                            required
                            value={value}
                            placeholder={placeholder}
                            autoComplete="off"
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ): void => setValue(e.target.value)}
                            onFocus={(): void => {
                                showOverlay(true);
                            }}
                        />
                        <button
                            className={styles.magnifier}
                            type="submit"
                            tabIndex={0}
                            aria-label={t("magnifier.ariaLabel")}
                        >
                            <span className="icon-magnifier" />
                        </button>
                    </form>
                    {overlayShown && (
                        <SuggestList
                            searchResult={suggestions ?? undefined}
                            searchValue={value}
                            onItemClick={(): void => {
                                showOverlay(false);
                                setValue("");
                            }}
                        />
                    )}
                </div>
            </>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
