const config = {
    localStorageKey: {
        de: "favorite-store",
        at: "favorite-store",
        ch: "favorite-store",
        pl: "favorite-store-pl",
    },
};

export default config;
